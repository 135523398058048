import {bindQuestionForm} from './question'
import {bindDonateForm} from './giving/donate'
import {bindRequestReceiptForm} from './giving/request-receipt'
import {bindPurchaseTicketForm} from './events/ticket'

function bindVerificationForms () {
  bindQuestionForm(document.getElementById('pocket-question-form'))
  bindDonateForm(document.querySelector('[id^="donate-form"]'))
  bindRequestReceiptForm(document.getElementById('request-receipt-form'))
  // bindPurchaseTicketForm(document.getElementById('reservation-form'))
}

window.bindVerificationForms = bindVerificationForms
