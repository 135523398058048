export function bindDonateForm (form) {
  if (form) {
    // submit
    // const submit = (token) => {
    //   // no ajax
    //   form.submit()
    // }
    // bind submit listener
    // form.addEventListener('submit', (e) => {
      // e.preventDefault()
      // const token = generateToken()
      // submit(token)
    // })

    // bind the recaptcha
    const widgetId = window.grecaptcha.render('donate-form-recaptcha', {
      'sitekey': window.GOOGLE_RECAPTCHA_KEY,
      theme: 'light'
    })
  }
}
