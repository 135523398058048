import {addHandler, removeHandler} from '../util/scroll_resize'

const initRegistry = []

export default function register (selector, func) {
  /* Register an init function for elements matching the selector. */

  initRegistry.push([selector, func])
}

function getInitFunction (el) {
  /* Get a matching init function for the element from the registry, based on
     the function's paired selector. */

  for (let i = 0; i < initRegistry.length; i++) {
    if (el.matches(initRegistry[i][0])) {
      return initRegistry[i][1]
    }
  }
}

export function initContainers (containers) {
  /* Initialises the given containers by calling the {classname}Init function
     (where classname is the container's first css class, camelcased) if it
     exists. Init functions may return a "handler" object, which may have
     the following methods:

       resize: called on window resize, see events.js
       scroll: called on window scroll, see events.js
       teardown: called when the container is removed from the DOM

     If a handler is returned, it is saved as a data attribute on the
     container.
  */

  function doInit (els, InitFunc) {
    const init = new InitFunc(els)
    if (init && (init.resize || init.scroll)) {
      addHandler(init, true)
    }
    return init
  }

  Array(...containers).forEach((container) => {
    const init = getInitFunction(container)

    if (init && !container.getAttribute('data-initialised')) {
      doInit(container, init)
      container.setAttribute('data-initialised', true)

      // TODO fix this
      // container.setAttribute('data-handler', doInit(container, init))
    }
  })
}

export function teardownContainers (containers) {
  /* Called when the given containers are removed from the DOM. Removes
     scroll/resize handlers and calls the container's teardown if one exists.
  */

  Array(...containers).forEach((container) => {
    // TODO fix this
    const handler = container.getAttribute('data-handler')
    if (handler) {
      removeHandler(handler)
      if (handler.teardown) handler.teardown()
    }
  })
}
