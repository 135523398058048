import Cookies from 'js-cookie'
import formurlencoded from 'form-urlencoded'

// function csrfSafeMethod (method) {
//   // these HTTP methods do not require CSRF protection
//   return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method))
// }

/**
 * submit POST
 *
 * @function post
 * @param {string} url
 * @param {htmlElement} form <form>
 * @param {htmlElement} append <form>
 * @return {response} data
**/

export function post (url, form, append) {
  const data = new window.FormData(form)
  // currently FormData does not include the submitted button
  // so I'm appending it
  // if (append) {
  //   for (let key in append) {
  //     data.append(key, append[key])
  //   }
  // }

  const params = {
    credentials: 'include',
    method: 'post',
    body: data,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFToken': Cookies.get('csrftoken')
      // 'Accept': 'application/json, text/plain, */*',
      // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  }

  return window.fetch(url, params)
}

/**
 * submit GET
 *
 * @function get
 * @param {string} url
 * @param {object} data
 * @return {response} data
**/

export function get (url, data) {
  if (typeof data === 'object') {
    data = formurlencoded(data)
    url = `${url}?${data}`
  }

  const params = {
    credentials: 'include',
    method: 'get',
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }

  return window.fetch(url, params)
}

/**
 * gets all errors as json object, then places them in their relevant location
 * within the form DOM, (same as it would for a hard load)
 *
 * @function displayErrors
 * @param {htmlElement} form <form>
 * @param {object} errors
**/

export function displayErrors (form, errors) {
  // start of by clearing all current errors
  form.querySelectorAll('.errorlist').forEach((el) => {
    el.innerHTML = ''
  })
  if (!errors) {
    return
  }
  if (typeof errors === 'string') {
    errors = {
      '__all__': [errors]
    }
  }
  var el, errorlist
  for (var fieldname in errors) {
    el = form.querySelector('.form-field.' + fieldname)
    if (!el) {
      // put non-field errors at the top of the form
      // TODO consider prefixed fieldset
      el = form
    }
    errorlist = el.querySelector('.errorlist')
    if (!errorlist) {
      errorlist = document.createElement('ul')
      errorlist.classList.add('errorlist')
      el.insertBefore(errorlist, el.firstChild)
    }
    if (typeof errors[fieldname] === 'string') {
      errors[fieldname] = [errors[fieldname]]
    }
    for (var i = 0; i < errors[fieldname].length; i++) {
      let li = document.createElement('li')
      li.innerHTML = errors[fieldname][i]
      errorlist.appendChild(li)
    }
  }
}

/**
 * reset all CSRF tokens, otherwise we need to reload the page.
 * ...
 *
 * @function updateCSRF
**/

export function updateCSRF () {
  document.querySelectorAll('[name="csrfmiddlewaretoken"]').forEach((token) => {
    token.value = Cookies.get('csrftoken')
  })
}
