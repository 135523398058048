import register from '../../util/init'

let widgetId = false

export function bindPurchaseTicketForm(form) {
  if (form) {
    // submit

    // const submit = token => {
    //   // no ajax
    //   form.submit()
    // }
    // bind submit listener
    // form.addEventListener('submit', e => {
    //   e.preventDefault()
    //   // const token = generateToken()
    //   // submit(token)
    // })
    // bind the recaptcha
    widgetId = window.grecaptcha.render('ticket-form-recaptcha', {
      sitekey: window.GOOGLE_RECAPTCHA_KEY,
      theme: 'light'
    })
  }
}

class ticketForm {
  constructor(container) {
    this.container = container
    this.ticketFormInit = this.ticketFormInit()
    this.guests = 1
    if (this.container.querySelector('input[name="guests-MAX_NUM_FORMS"]')) {
      this.maxTickets = parseInt(
        this.container.querySelector('input[name="guests-MAX_NUM_FORMS"]').value
      )
    } else {
      this.maxTickets = 0
    }
    if (widgetId) {
      bindPurchaseTicketForm(document.getElementById('reservation-form'))
    } else if (window.grecaptcha && window.grecaptcha.render) {
      bindPurchaseTicketForm(document.getElementById('reservation-form'))
    }
  }

  ticketFormInit() {
    const addGuestButton = this.container.querySelector(
      '.reservation-form .add-guest'
    )
    if (addGuestButton) {
      addGuestButton.addEventListener('click', e => {
        e.preventDefault()
        this.addGuest()
      })

      const removeGuestButton = this.container.querySelector(
        '.reservation-form .remove-guest'
      )

      removeGuestButton.addEventListener('click', e => {
        e.preventDefault()
        this.removeGuest()
      })

      const emailInput = this.container.querySelector(
        '.reservation-form .emailinput'
      )
      const ticketType = this.container.querySelector(
        '.reservation-form .ticket_type'
      )
      ticketType.parentNode.insertBefore(emailInput, ticketType)
    }
  }

  addGuest() {
    if (this.guests < this.maxTickets) {
      this.guests += 1
      const guestForm = this.container.querySelector(
        '.reservation-form .guest-form'
      )
      let newGuestForm = guestForm.cloneNode(true)

      newGuestForm.querySelectorAll('.emailinput').forEach(el => el.remove())
      newGuestForm.querySelectorAll('p.error').forEach(el => el.remove())
      newGuestForm
        .querySelectorAll('.error')
        .forEach(el => el.classList.remove('error'))
      newGuestForm.querySelectorAll('.errorlist').forEach(el => el.remove())
      newGuestForm.querySelectorAll('input[type="text"]').forEach(el => {
        el.removeAttribute('value')
      })
      const buttons = this.container.querySelector(
        '.reservation-form .guest-buttons'
      )
      guestForm.parentNode.insertBefore(newGuestForm, buttons)
      newGuestForm.innerHTML = newGuestForm.innerHTML.replace(
        /guests-0/g,
        `guests-${this.guests - 1}`
      )

      const totalForms = this.container.querySelector(
        '.reservation-form #id_guests-TOTAL_FORMS'
      )
      totalForms.value = parseInt(totalForms.value) + 1

      const removeGuestButton = this.container.querySelector(
        '.reservation-form .remove-guest'
      )
      removeGuestButton.classList.add('active')

      if (this.guests === this.maxTickets) {
        const addGuestButton = this.container.querySelector(
          '.reservation-form .add-guest'
        )
        addGuestButton.classList.add('disable')
      }
    }
  }

  removeGuest() {
    const guestForms = [
      ...this.container.querySelectorAll('.reservation-form .guest-form')
    ]
    if (guestForms.length > 1) {
      guestForms[guestForms.length - 1].remove()

      this.guests -= 1

      const totalForms = this.container.querySelector(
        '.reservation-form #id_guests-TOTAL_FORMS'
      )
      totalForms.value = this.guests

      const addGuestButton = this.container.querySelector(
        '.reservation-form .add-guest'
      )
      addGuestButton.classList.remove('disable')

      if (this.guests === 1) {
        const removeGuestButton = this.container.querySelector(
          '.reservation-form .remove-guest'
        )
        removeGuestButton.classList.remove('active')
      }
    }
  }
}

register('.event-ticket', ticketForm)
