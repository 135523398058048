import {prev, closest} from '../../util/tools'

export function bindRequestReceiptForm (form, prefix) {
  if (form) {
    const addInline = (addTrigger, prefix) => {
      // clone an inline - currently no remove method
      const lastInline = prev(addTrigger.parentElement)
      const clonedInline = lastInline.cloneNode(true)
      const prefixStr = prefix ? `_${prefix}` : '_form'

      let totalForms = form.querySelector(`#id${prefixStr}-TOTAL_FORMS`)
      let total = parseInt(totalForms.value)

      totalForms.value = total + 1

      clonedInline.querySelectorAll('input').forEach((ci) => {
        if (ci.getAttribute('name') && ci.getAttribute('id')) {
          ci.value = ''
          ci.setAttribute('name', ci.getAttribute('name').replace(`-${total - 1}-`, `-${total}-`))
          ci.setAttribute('id', ci.getAttribute('id').replace(`-${total - 1}-`, `-${total}-`))
        }
      })
      lastInline.parentNode.insertBefore(clonedInline, addTrigger.parentElement)
    }

    // submit
    const submit = (token) => {
      let validationState = true
      let lastInvalidInput = null
      // no ajax
      form.querySelectorAll('[required]').forEach((field) => {
        if (field.checkValidity !== undefined && !field.checkValidity()) {
          const formField = closest(field, '.form-field')
          if (!formField.querySelector('.error-message')) {
            let errorMessage = document.createElement('p')
            errorMessage.innerHTML = 'This field is required'
            errorMessage.classList.add('error-message')
            formField.insertBefore(errorMessage, formField.firstChild)
          }
          formField.classList.add('error')
          validationState = false
          lastInvalidInput = field
        }
      })
      if (validationState) {
        form.submit()
      } else {
        window.grecaptcha.reset(widgetId)

        window.scroll({
          top: lastInvalidInput.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
    // bind submit listener
    form.addEventListener('submit', (e) => {
      e.preventDefault()
      // const token = generateToken()
      // submit(token)
    })

    form.querySelectorAll('.add-another-inline').forEach((addTrigger) => {
      addTrigger.addEventListener('click', (e) => {
        e.preventDefault()
        addInline(addTrigger)
      })
    })

    // bind the recaptcha
    const widgetId = window.grecaptcha.render('request-receipt-form-recaptcha', {
      'sitekey': window.GOOGLE_RECAPTCHA_KEY,
      'size': 'invisible',
      'callback': submit
    })
  }
}
