import {post, displayErrors, updateCSRF} from '../util/fetch'

export function bindQuestionForm (form) {
  if (form) {
    // submit
    const submit = (token) => {
      //   const token = window.grecaptcha.getResponse(widgetId)
      //   if (!token) {
      //     window.grecaptcha.reset(widgetId)
      //     window.grecaptcha.execute(widgetId)
      //   }
      let valid = true
      form.querySelectorAll('[required]').forEach((field) => {
        if (!field.value) {
          field.style.borderColor = '#ee463b'
          valid = false
        }
      })
      if (!valid) return 

      const url = `${form.getAttribute('action')}?is_ajax=1`

      post(url, form).then((response) => {
        return response.json()
      }).then((data) => {
        if (data.success) {
          updateCSRF()
          // return thanks
          form.classList.add('state-complete')
          form.querySelector('.messages .info').innerHTML = data.message
          // // track ananlytics (https://developers.google.com/analytics/devguides/collection/analyticsjs/events#outbound_link_and_form_tracking)
          // if (typeof window.ga !== 'undefined' && window.ga !== null) {
          //   window.ga('send', 'event', {
          //     eventCategory: 'Interested form',
          //     eventAction: 'submit success'
          //   })
          // }
          // track ananlytics through gtm https://developers.google.com/tag-manager/devguide#events
          // if (typeof window.dataLayer !== 'undefined' && window.dataLayer !== null) {
          //   window.dataLayer.push({
          //     'event': 'interestedForm',
          //     'attributes': {
          //       'success': true
          //     }
          //   })
          // }
        } else {
          // handle error
          displayErrors(form, data.errors)
        }
      })
    }
    // bind submit listener
    form.addEventListener('submit', (e) => {
      e.preventDefault()
      // const token = generateToken()
      // submit(token)
    })

    // bind the recaptcha
    const widgetId = window.grecaptcha.render('pocket-question-form-recaptcha', {
      'sitekey': window.GOOGLE_RECAPTCHA_KEY,
      'size': 'invisible',
      'callback': submit
    })
  }
}
